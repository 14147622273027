<template>
  <div class="page-tabs"> 
    <el-tabs v-model="activeName" type="card" closable @tab-remove="removeTab" @tab-click="changePage"> 
      <el-tab-pane
        v-for="({meta, name}) in tabList"
        :key="name"
        :label="meta.title"
        :name="name"
      ></el-tab-pane>
    </el-tabs>
  </div>
</template>
<script>
export default {
  data() {
    return {
      activeName: ''
    }
  },
  computed: {
    tabList() {
      return this.$store.state.tabList
    },
    activeRoute() {
      return this.$store.state.activeRoute
    },
  },
  watch: {
    activeRoute() {
       this.activeName = this.activeRoute
    }
  },
  methods: {
    removeTab(name) {
      this.$store.dispatch('removeTab', name)
    },
    changePage(tab) {
      let name = tab.name
      if (name != this.$route.name) {
        this.$router.push(name)
      }
    }
  }
}
</script>