<template>
  <el-card class="shop-auth">
    <div class="step-box">
      <el-steps :active="stepIndex" align-center>
        <el-step :title="$t('dian-pu-she-zhi')"></el-step>
        <el-step :title="$t('dian-pu-ren-zheng')"></el-step>
        <el-step :title="$t('shang-jia-shang-pin')"></el-step>
      </el-steps>

      <div class="info">
        {{
          $t(
            'qing-nin-wan-shan-dian-pu-xin-xi-yi-bao-zheng-gu-ke-neng-zheng-chang-fang-wen-dao-nin'
          )
        }}
      </div>
      <el-button
        type="primary"
        size="small"
        @click="toSetting"
        v-if="stepIndex == 0"
        >{{ $t('li-ji-she-zhi') }}</el-button
      >
      <el-button
        type="primary"
        size="small"
        @click="toAuth"
        v-if="stepIndex == 1"
        >{{ $t('li-ji-ren-zheng') }}</el-button
      >
      <el-button
        type="primary"
        size="small"
        @click="toProduct"
        v-if="stepIndex == 2"
        >{{ $t('shang-jia-shang-pin') }}</el-button
      >
    </div>
  </el-card>
</template>
<script>
export default {
  data() {
    return {
      stepIndex: 0
    }
  },
  computed: {
    isShopAuth() {
      return this.$store.state.isShopAuth
    },
    FirstSetup() {
      return this.$store.state.FirstSetup
    }
  },
  watch: {
    isShopAuth() {
      this.init()
    },
    FirstSetup() {
      this.init()
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      this.stepIndex = 0
      if (this.FirstSetup && this.isShopAuth) {
        this.stepIndex = 2
      } else if (this.FirstSetup && !this.isShopAuth) {
        this.stepIndex = 1
      }
    },
    toSetting() {
      this.$router.push({
        name: 'shopSetting'
      })
    },
    toAuth() {
      this.$router.push({
        name: 'shopSetting',
        query: {
          type: 'auth'
        }
      })
    },
    toProduct() {
      this.$router.push({
        name: 'shopProduct'
      })
    }
  }
}
</script>