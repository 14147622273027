<template>
  <div class="page-header">
    <div class="left">
      <i
        :class="showMenu ? 'el-icon-s-fold' : 'el-icon-s-unfold'"
        style="font-size: 20px"
        @click="changeShow"
      ></i>
      <span class="name">{{ pageName }}</span>
    </div>
    <div class="right">
      <el-badge :value="noticeCount" class="item" v-if="noticeCount > 0">
        <i class="el-icon-bell" style="font-size: 20px"></i>
      </el-badge>
      <i class="el-icon-bell" v-else style="font-size: 20px"></i>

      <el-dropdown trigger="click">
        <div class="lang-info flex-center">
          {{ langName }}
          <img :src="langIcon" alt="" />
          <i
            class="el-icon-caret-bottom"
            style="color: #333; font-size: 14px"
          ></i>
        </div>
        <el-dropdown-menu slot="dropdown" class="lang-dropdown">
          <el-dropdown-item v-for="(item, i) in langList" :key="i">
            <div class="flex-center-start lang-menu" @click="changeLang(item)">
              <img :src="item.icon" alt="" />
              <span>{{ item.name }}</span>
            </div>
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>

      <div class="user-info">
        <div class="user-header mini header-1"></div>
        <div class="username">{{ userName }}</div>
      </div>

      <div class="logout" @click="logout">
        <i
          class="el-icon-switch-button"
          style="color: #f00; font-size: 20px"
        ></i>
      </div>
    </div>
  </div>
</template>
<script>
import { removeToken } from '@/utils/auth'
export default {
  data() {
    return {
      showMenu: true,
      pageName: "",
      noticeCount: 0
    }
  },
  computed: {
    langName() {
      return this.$store.state.langName
    },
    langIcon() {
      return this.$store.state.langIcon
    },
    langList() {
      return this.$store.state.langList
    },
    lang() {
      return this.$store.state.lang
    },
    userName() {
      return this.$store.state.shopUserName
    }
  },
  mounted() {
    this.pageName = this.$t("shou-ye")
  },
  methods: {
    changeShow() {
      this.showMenu = !this.showMenu
    },
    changeLang(data) {
      this.showLang = false
      this.i18n.locale = data.key
      localStorage.setItem('locale', data.key)
      this.$store.state.lang = data.key
      this.$store.state.langIcon = data.icon
      this.$store.state.langName = data.name
    },
    logout() {
      removeToken()
      this.$store.state.isLogin = false
      this.$router.push({
        name: 'login'
      })
    }
  }
}
</script>