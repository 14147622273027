<template>
  <div>
    <div v-for="({name, meta, children}) in menuList" :key="name">
      <el-submenu v-if="children && children.length > 0" :index="name">
        <template #title>
          <div class="menu-title">
            <i :class="meta.icon"></i>
            {{ meta?.title }}
          </div>
        </template>
        <MenuItem :menus="children" />
      </el-submenu>
      <el-menu-item v-else :index="name">
        <div class="menu-title">
          <i :class="meta.icon"></i>
          {{ meta?.title }}
        </div>
      </el-menu-item>
    </div>
  </div>
</template>

<script >
export default {
  name: 'MenuItem',
  props: {
    menus: {
      type: Array,
      default: () => []
    },
  },
  data() {
    return {

    }
  },
  computed: {
    menuList() {
      return this.menus
    }
  },
  mounted() {
  }
}
</script>
